import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import { getLocationProperty,getPrice,searchTour,searchYoutube,get_short_desc,addFavorites, getfavorites} from "../../functions/functions_property";
import { updateFields,formTokko } from '../../redux/contactDucks'
import { getFavoritesAction,clearFavoritesAction } from '../../redux/propertiesDucks'
import Map from "../map";

const Data = () =>{
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);
    const [disabled, setDisabled] = useState(true);
    const property = useSelector((store) => store.properties.property).data;
    const [hidden,setHidden] = useState(true)
    const [text,setText] = useState('')
    const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
    const [max_caracters, setMax_caracter] = useState(700);

    const checkActive = (id) => {
        setListFavorites(getfavorites("prop",settings.short_name))
        dispatch(clearFavoritesAction())
        for (const iterator of getfavorites("prop",settings.short_name)) {
            //Por cada uno de los favoritos, traigo su respectiva data de la api
            dispatch(getFavoritesAction(iterator))
        }
    } 
    const [datos, setDatos] = useState({
        nombre:'',
        apellido:'',
        email:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
      event.preventDefault()
      dispatch(formTokko('Propiedad',property.fake_address,property.id))
      document.getElementById("formContact").reset()
      setText("¡Muchas gracias! en breve nos estaremos contactando con ud.")
  }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])
    return property ? (
    <section className="data">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-12">
                <BreadCrumbs
                    props={[
                        { name: "Home", route: settings.base_url, location: "" },
                        {
                        name: "Venta",
                        route: settings.base_url + "ventas",
                        location: "ventas",
                        },
                        {
                        name: property.type.name,
                        route: settings.base_url + "ventas/" + property.type.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        location: "ventas",
                        },
                        {
                        name: property.fake_address,
                        route: settings.base_url,
                        location: "",
                        active: true,
                        },
                    ]}
                    />
                </div>
                <div className="col-12 tags d-flex d-lg-none align-items-start">
                    <div className="tag text-uppercase">
                        {property.operations[0].operation_type}
                    </div>
                    <div className="tag primary-tag ms-1 text-uppercase">
                        {property.type.name}
                    </div>
                </div>
                <div className="col-lg-8">
                    <h2>
                        {property.fake_address}
                        <br />
                        <div className="location">
                            {" "}
                            {getLocationProperty(property.location)}{" "}
                        </div>
                    </h2>
                </div>
                <div className="col-lg-4 d-flex justify-content-lg-end div_button_propiedad">
                    {/* <button className={"btn transparent " + (searchTour(property.videos) && property.videos.length > 0 ? "d-block" : 'd-none') }>
                        <i className="icon-tour-360"></i> TOUR VIRTUAL
                    </button> */}
                    <div className="d-flex align-items-center order-lg-1 order-2">
                        <div className={'d-flex align-items-center me-2 social-block order-lg-1 order-2 ' + (hidden ? 'hidden' : '')}>
                            <a href={'mailto:' + '?subject='  + property.fake_address + '&body=' + window.location} className="btn p-0 d-flex align-items-center justify-content-center"><i className="icon-send"></i></a>
                            <a href={"https://api.whatsapp.com/send?text=Hola, quería compartirte esta propiedad" + " " + window.location} target="_blank" className="btn p-0 d-flex align-items-center justify-content-center"><i className="icon-whatsapp"></i></a>
                        </div>
                        <button onClick={() => setHidden(!hidden)} className="btn small d-flex justify-content-center align-items-center order-1 order-lg-2 ">
                            <i className="icon-share"></i>
                        </button>
                    </div>
                    <button onClick={() => addFavorites(property.id,"prop",settings.short_name) + checkActive()} className={"btn small d-flex justify-content-center align-items-center order-1 order-lg-2 " + (listFavorites.find(element => element.toString() === property.id.toString()) ? 'active' : '')}>
                        <i className="icon-heart"></i>
                    </button>
                </div>
            </div>
            <div className="row mt-lg-5 mt-3">
                <div className="col-lg-7 d-lg-block d-none">
                    <div className="tags d-none d-lg-flex align-items-start">
                        {property.operations.map((item) =>(
                            <div className={"tag me-2 text-uppercase " + item.operation_type}>
                                {item.operation_type}
                            </div>
                        ))}
                        <div className="tag primary-tag ms-0 text-uppercase">{property.type.name}</div>
                    </div>
                        {property.total_surface > 0 || property.roofed_surface > 0 ? 
                            <div className="item-extra d-flex mt-5">
                                <i className="icon-sup me-4"></i>
                                {property.total_surface > 0 ? new Intl.NumberFormat("de-DE").format(parseInt(property.total_surface).toFixed(0)) + " m2 totales " : ''} 
                                {property.roofed_surface > 0 ? " / " + new Intl.NumberFormat("de-DE").format(parseInt(property.roofed_surface).toFixed(0)) + " m2 cubiertos " : ''} 
                                {property.roofed_surface > 0 || property.total_surface > 0 ? ' / ' : ''}
                                {property.surface > 0 ?  new Intl.NumberFormat("de-DE").format(parseInt(property.surface)) + ' m2 de terreno' : ''}
                            </div>
                        :''}
                        {property.room_amount > 0 || property.suite_amount  > 0 ?
                            <div className="item-extra d-flex ">
                                <i className="icon-bed me-4"></i>
                                {property.room_amount > 0 ? property.room_amount + " ambiente" + (property.room_amount > 1 ? 's' : '') : ''} 
                                {property.suite_amount  > 0 ? " / " + property.suite_amount + ' dormitorio' + (property.suite_amount > 1 ? 's' : '') : " "} 
                            </div>
                        :''}
                        {property.bathroom_amount > 0 || property.toilet_amount  > 0?
                        <div className="item-extra d-flex ">
                        <i className="icon-bathroom me-4"></i>
                            {property.bathroom_amount > 0 ? property.bathroom_amount + " baño" + (property.bathroom_amount > 1 ? 's' : '') : '' } 
                            {property.toilet_amount  > 0 ? " / " + property.toilet_amount + ' toilette' + (property.toilet_amount > 1 ? 's' : '') : " "} 
                        </div>
                        :''}
                        {property.parking_lot_amount > 0 ?
                            <div className="item-extra d-flex ">
                            <i className="icon-car me-4"></i>
                                {property.parking_lot_amount > 0 ? property.parking_lot_amount + ' cochera' + (property.parking_lot_amount > 1 ? 's' : '')  : ''}
                            </div>
                        :''}
                        <div className={"item-extra d-flex " + (property.custom_tags.length > 0 ? 'd-block':'d-none')}>
                        <i className="icon-gym me-4"></i>
                            {property.custom_tags.map((item,index) =>(
                                <>
                                {item.name}
                                {index > 0 ? ' / ' : ''}
                                </>
                            ))}
                        </div>
                    
                </div>
                <div className="col-12 d-block d-lg-none">
                    {property.total_surface > 0 || property.roofed_surface > 0 ? 
                        <div className="item-extra d-flex mt-3">
                            <i className="icon-sup me-4"></i>
                            {property.total_surface > 0 ? new Intl.NumberFormat("de-DE").format(parseInt(property.total_surface).toFixed(0)) + " m2 totales " : ''} 
                            {property.roofed_surface > 0 ? " / " + new Intl.NumberFormat("de-DE").format(parseInt(property.roofed_surface).toFixed(0)) + " m2 cubiertos " : ''} 
                            {property.roofed_surface > 0 || property.total_surface > 0 ? ' / ' : ''}
                            {property.surface > 0 ?  new Intl.NumberFormat("de-DE").format(parseInt(property.surface)) + ' m2 de terreno' : ''}
                        </div>
                    :''}
                    {property.room_amount > 0 || property.suite_amount  > 0 ?
                        <div className="item-extra d-flex ">
                            <i className="icon-bed me-4"></i>
                            {property.room_amount > 0 ? property.room_amount + " ambiente" + (property.room_amount > 1 ? 's' : '') : ''} 
                            {property.suite_amount  > 0 ? " / " + property.suite_amount + ' dormitorio' + (property.suite_amount > 1 ? 's' : '') : " "} 
                        </div>
                    :''}
                    {property.bathroom_amount > 0 || property.toilet_amount  > 0?
                    <div className="item-extra d-flex ">
                    <i className="icon-bathroom me-4"></i>
                        {property.bathroom_amount > 0 ? property.bathroom_amount + " baño" + (property.bathroom_amount > 1 ? 's' : '') : '' } 
                        {property.toilet_amount  > 0 ? " / " + property.toilet_amount + ' toilette' + (property.toilet_amount > 1 ? 's' : '') : " "} 
                    </div>
                    :''}
                    {property.parking_lot_amount > 0 ?
                        <div className="item-extra d-flex ">
                        <i className="icon-car me-4"></i>
                            {property.parking_lot_amount > 0 ? property.parking_lot_amount + ' cochera' + (property.parking_lot_amount > 1 ? 's' : '')  : ''}
                        </div>
                    :''}
                    <div className={"item-extra d-flex " + (property.custom_tags.length > 0 ? 'd-block':'d-none')}>
                    <i className="icon-gym me-4"></i>
                        {property.custom_tags.map((item,index) =>(
                            <>
                            {item.name}
                            {index > 0 ? ' / ' : ''}
                            </>
                        ))}
                    </div>
                </div> 
                <div className="col-lg-5 d-none d-lg-block">
                    <div className="row">
                        {property.tags.map((item, index) => (
                            <div className="col-6 mb-3">
                                <div className="item d-flex">
                                    <div className="tick d-flex justify-content-center align-items-center me-3">
                                    <i className="icon-check"></i>
                                    </div>
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <hr />
                <div className="col-lg-12">
                    <div className="content-price">
                        {property.operations.map((item)=>(
                            <h3 className={item.operation_type + (property.web_price ? '' : ' min-size')}>{property.web_price ? getPrice(item.prices[0]) : 'Precio a consultar'}</h3>
                        ))}
                    </div>
                </div>
                <div className="col-12 d-block d-lg-none">
                    <div className="row">
                        {property.tags.map((item, index) => (
                            <div className="col-6 mb-3">
                                <div className="item d-flex">
                                    <div className="tick d-flex justify-content-center align-items-center me-3">
                                    <i className="icon-check"></i>
                                    </div>
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={"col-lg-12 " + (property.description ? 'd-block' : 'd-none')}>
                    <h2 className="sub_title">Descripción.</h2>
                    <p>
                        {get_short_desc(property.description, max_caracters).map((element) => (
                            <>
                                {element}
                                <br />
                            </>
                        ))}
                    </p>
                    <a
                        className="cursor-pointer see_description"
                        onClick={() =>
                        max_caracters == 700
                            ? setMax_caracter(9999)
                            : setMax_caracter(700)
                        }
                    >
                        {property.description.length > 700
                        ? max_caracters == 700
                            ? "Ver más.."
                            : "Ver menos.."
                        : ""}
                    </a>
                </div>
                <div className={"col-lg-12 " + (searchTour(property.videos)  ? "d-block" : 'd-none') }>
                    <h2 className="sub_title">Tour Virtual</h2>
                    <iframe className="global_view" allowfullscreen="" src={searchTour(property.videos).player_url} frameborder="0"></iframe>
                </div>
                <div className={"col-lg-12 " + (searchYoutube(property.videos) ? 'd-block' : 'd-none')}>
                    <h2 className="sub_title mb-lg-4">Video</h2>
                    <iframe className="global_view" allowfullscreen="" src={searchYoutube(property.videos) ? searchYoutube(property.videos).player_url : ''} frameborder="0"></iframe>
                    {}
                </div>
                <div className="col-lg-12 mb-4 mt-lg-5 py-lg-3">
                    <h2 className="sub_title">Ubicación.</h2>
                </div>
            </div>
        </div>
        <div className="py-3">
            <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
        </div>
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-lg-12 contact-main pt-lg-5 px-lg-3 px-2">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 style={{'font-weight':'200'}}>
                                Consultanos por <br />
                                esta  propiedad.
                            </h2>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="content-contact d-none d-lg-block">
                                <span className="d-block">MA: <a href={"mailto:" + settings.contact.mail}>{settings.contact.mail}</a></span>
                                <span className="d-block">TE: <a href="#"> {settings.contact.phone[0] + ' / ' + settings.contact.phone[1]}</a></span>
                            </div>
                        </div> */}
                    </div>
                    <form onSubmit={sendForm} id="formContact" action="">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column align-items-start order-1 order-lg-1">
                                <input type="text" name="nombre" placeholder="Nombre y apellido" onChange={handleInputChange} id="" />
                                <input type="text" className="mt-lg-5 mb-0" name="cellphone" onChange={handleInputChange} placeholder="Telefono" id="" />
                            </div>
                            <div className="col-lg-6 order-2 order-lg-3">
                                <input type="text" name="email" className="mt-lg-5" onChange={handleInputChange} placeholder="E-mail *" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required id="" />
                            </div>
                            <div className="col-lg-6 order-3 order-lg-2">
                                <textarea name="text" placeholder="Mensaje" onChange={handleInputChange} id="" cols="30"></textarea>
                                <p className="campos_obligatorios position-absolute mt-0" >*Campos obligatorios</p>
                            </div>
                            <div className="col-lg-6  order-lg-4 order-4 mt-lg-5 d-flex justify-content-around div_form_captcha">
                                <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
                                <div className="d-block ">
                                    <span id="text-form" className={(text == "" ? 'd-none' : 'd-block') + ' mt-4'}>{text}</span>
                                </div>  
                                <button disabled={disabled} href="#" className="btn btn-effect transparent d-inline-block "  ><p className="btn_propiedad_enviar">ENVIAR</p></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>): (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
}
export default Data