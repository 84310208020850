import axios from 'axios'

// constantes
const dataInicial = {
    developments: [],
    development:{},
    units_development:{},
    stateCodes : [
        {},
        {},
        {},
        {
            id:3,
            name:"En pozo"
        },
        { 
            id:4,
            name:"En Construcción"
        },
        {},
        {
            id:6,
            name:"Construcción Terminada"
        }
    ],
}

// types
const GET_DEVELOPMENT = 'GET_DEVELOPMENT'
const GET_UNITS_DEVELOPMENT = 'GET_UNITS_DEVELOPMENT'
const GET_DEVELOPMENTS = 'GET_DEVELOPMENTS'
const CLEAN_DEVELOPMENT = 'CLEAN_DEVELOPMENT'

// reducer
export default function developmentReducer(state = dataInicial, action){
    switch(action.type){
        case GET_DEVELOPMENT:
            return {...state, development : action.payload }
        case GET_DEVELOPMENTS:
            return {...state, developments : action.payload }
        case GET_UNITS_DEVELOPMENT:
            return {...state, units_development : action.payload }
        case CLEAN_DEVELOPMENT:
            return {...state, development : action.payload }
        default:
            return state
    }
}

// actions
export const getDevelopmentAction = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/' + id +'?cache=true' ,{
            headers:{
                'X-TOKKO-KEY': getState().settings.keys.tokko
            },
        })
        dispatch({
            type: GET_DEVELOPMENT,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
}
export const getUnitsDevelopmentAction = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://www.tokkobroker.com/api/v1/property/?development__id=' + id +'&key=' + getState().settings.keys.tokko +'&format=json')
        dispatch({
            type: GET_UNITS_DEVELOPMENT,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
}
export const getDevelopmentsAction = () => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/search?cache=false',{
            headers:{
                'X-TOKKO-KEY': getState().settings.keys.tokko
            },
        })
        dispatch({
            type: GET_DEVELOPMENTS,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
}
export const cleanDevelopmentAction = () => (dispatch, getState) => {
    dispatch({
        type: CLEAN_DEVELOPMENT,
        payload: {}
    })
}