import React from 'react'
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import { changeLocationAction } from '../../redux/themeDucks';

import Image from '../../assets/images/bg-about.jpg'

function About (){
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    return  (
        <div className="about-section">
            <div className="top-image">
                <img src={Image} alt="" />
            </div>
            <div className="container-fluid position-relative">
                <div className="col-12 d-lg-flex d-none justify-content-end">
                    <div className="content-logo">	
                        <img className="logo-absolute rotating" src="assets/images/circuloHome.svg" alt="" />
                    </div>
                </div>
                <h2>NOSOTROS</h2>
                <div className="row">
                    <div className="col-lg-4">
                        <span>Desde 2003 brindando el mejor servicio.</span>
                    </div>
                    <div className="col-lg-7 offset-lg-1">
                        <p>
                            Somos una empresa con 18 años en el mercado inmobilario brindando <br /> respeto por nuestros clientes, profesionalismo, confidencialidad, transparencia, <br /> honestidad, y seguridad en todas las operaciones realizadas.
                        </p>
                        <Link to={settings.base_url + "nosotros"} onClick={() => dispatch(changeLocationAction("nosotros"))} className="btn d-none d-lg-block btn-effect btn-movil-white  d-inline-block d-block btn_form_tasacion"><span></span>QUIERO CONOCER MÁS</Link>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

export default About
