import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentsAction } from '../redux/developmentsDucks';


// -- Componentes -- //

import Main from '../components/emprendimientos/main'
import Lotes from '../components/emprendimientos/lotes'
import Investors from '../components/emprendimientos/investors'



function Emprendimientos(){
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    useEffect(() => {
        const getDevs = () => {
            dispatch(getDevelopmentsAction())
            window.scrollTo(0, 0);
            document.title = settings.seo.title
        }
        getDevs()
    }, [])

    return(
        <div className="emprendimientos">
            <Main />
            <Lotes />
            {/* <Investors /> */}
        </div>
    )
}

export default Emprendimientos
