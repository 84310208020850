import { useSelector,useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'

const Main = () => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const settings = useSelector((store) => store.settings);
    const [disabled, setDisabled] = useState(true);
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
      event.preventDefault()
      setStep(2)
      window.scrollTo(0, 0)
      dispatch(formTokko('Contacto',null))
  }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])

    return(
        <section className={'main ' + (step == 2 ? 'step-2' : '')}>
            <div className="container-fluid full-fluid">
                <div className={'row align-items-center ' + (step == 1 ? 'pt-0' : '')}>
                    <div className={'left-column align-items-center col-lg-7 ' + (step == 2 ||step == 1 ? 'pt-0' : '') + (step == 0 ? ' min-height-full ' : '')}>
                        {step == 2 ?
                            <div className={'d-flex animate__animated animate__fadeInRight animate_faster flex-column justify-content-center ' + + (step == 2 ? 'pt-0' : '')}>
                                <div className="tick d-flex justify-content-center align-items-center">
                                    <i className="icon-check"></i>
                                </div>
                                <h2 className="text-thanks">
                                    Tu mensaje fue <br />
                                    enviado con éxito! <br />
                                    Gracias por contactarte <br />
                                    con nosotros <br />
                                </h2>
                            </div>
                        :
                            <div className={'d-flex flex-column justify-content-center position-relative ' + ( step == 1 ? 'd-none d-lg-flex' : '')}>
                                <BreadCrumbs props={[
                                    {name:'Home',route:settings.base_url,location:''},
                                    {name:'Contacto',route:'',location:'',active:true}
                                ]}/>
                                <span className="mb-lg-4">Contacto</span>
                                <h2>
                                    {settings.location.address} <br />
                                    {settings.location.region} <br />
                                    {settings.contact.phone[0]} <br />
                                    <a className="hover" href={"mailto:"+settings.contact.mail}>{settings.contact.mail}</a>
                                </h2>
                            </div>
                        }
                    {step == 0 ?
                            <a onClick={() => setStep(1)} className="btn btn-effect transparent d-inline-block btn_consulta"><p>CONSULTANOS AHORA</p></a>
                        :''}
                    </div>
                    {step == 0 ?
                        //STEP 1
                        <div className="col-lg-5 img">
                                <img src="./assets/images/img_contacto.jpg" alt="" />
                        </div>
                    :
                        step == 1 ? //STEP 2
                        <form onSubmit={sendForm} className="col-lg-5 animate__animated animate__fadeInRight animate_faster formulario d-flex flex-column justify-content-lg-center">
                            <input type="text" name="nombre" onChange={handleInputChange} placeholder="Nombre y apellido" />
                            <input type="text" name="cellphone" onChange={handleInputChange} placeholder="Telefono" />
                            <input type="text" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required onChange={handleInputChange} placeholder="E-mail*" />
                            <textarea name="text" onChange={handleInputChange} id="" placeholder="Mensaje" cols="30" rows="3"></textarea>
                            <div className="d-lg-flex justify-content-between w-100">
                                <div className="div w-100 order-2 order-lg-1 ">
                                    <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
                                    <button disabled={disabled} type="submit" id="btn-state" className="btn btn-effect btn_consulta transparent d-inline-block mt-3"><p>ENVIAR</p></button>
                                </div>
                                <p className="w-100 mt-lg-0 mt-4 text-lg-end order-1 order-lg-2" style={{'font-size' : 1.1 + 'rem'},{'font-weight': '400'}}>*Campos obligatorios.</p>
                            </div>
                        </form>
                        :''
                    }
                </div>
            </div>
        </section>
    )
}

export default Main