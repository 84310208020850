import { Link , useLocation } from "react-router-dom";
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Header from './header'


function Footer(){

    const [offset, setOffset] = useState(0);
    const [coordenadas,setCoordenadas] = useState(4000)
    const settings = useSelector(store => store.settings)
    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
            setCoordenadas(($("footer").offset()).top)
        }
      }, []);
    function scrollTop (){
        $('html, body').scrollTop(0)
        return true;
    }

    // get function to return the current year
    function getYear() {
        return new Date().getFullYear();
    }


    return(
        <div>
            <footer className="text-center text-lg-start" id="footer">
                    <div class="fixed-bar position-fixed">
                        {/* <a href={'https://web.whatsapp.com/send?text=¡Hola!&amp;phone=+5493644571181'} target="_blank" class="icon-whatsapp contact-btn d-none d-lg-flex"></a> */}
                        <a href={'https://api.whatsapp.com/send?phone=+'  + settings.contact.whatsapp.link} target="_blank" class={"icon-whatsapp contact-btn d-flex " + (offset > document.body.clientHeight * 0.7 && offset > 300 ? ' disabled ' : '')}></a>
                        <div onClick={() => scrollTop()} className={'btn d-none d-lg-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn ' + (offset > document.body.clientHeight * 0.7 && offset > 300 ? 'active' : '')}><span className="icon-arrow"></span></div>
                    </div>
                    <div className={"scroll-bar position-fixed d-block d-lg-none "}>
                        <div onClick={() => scrollTop()} className={'btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn ' + (offset > document.body.clientHeight * 0.7 && offset > 300 ? 'active' : '')}><span className="icon-arrow"></span></div>
                    </div>
                <div className="container-fluid">
                    <div className="row footer-nav">
                        <Header/>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-lg-3">
                            {/* <img src={settings.base_url +"assets/images/black-giesso-logo.svg"} alt="Giesso Propiedades" className="logo"/> */}
                            <div className="mt-5 enrollment-wrap">
                                <span className="text-uppercase enrollment-text">Matrícula</span>
                                <span className="enrollment-number">5145</span>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-5 ps-lg-5">
                            <div className="content">
                                <h5>OFICINA</h5>
                                <p>
                                    {settings.location.address}  <br />
                                    {settings.location.region}<br />
                                    CP: {settings.location.cp}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-5 d-flex justify-content-center">
                            <div className="content d-flex flex-column align-items-lg-start align-items-center">
                                <h5>CONTACTO</h5>
                                <p>
                                    TE: {settings.contact.phone[0]} <br />
                                    WA: <a href={"https://web.whatsapp.com/send?text=¡Hola!&phone=" + settings.contact.whatsapp.link} target="_blank" rel="noopener noreferrer"> {settings.contact.whatsapp.visual}</a><br />
                                    MA: <a href={'mailto:' + settings.contact.mail}>{settings.contact.mail} </a>
                                </p>
                            </div>
                        </div>
                        <div className="d-none btn-filters"></div>
                        <div className="col-lg-3 mt-5 d-flex justify-content-lg-end justify-content-center">
                            <div className="content d-flex flex-column align-items-lg-start align-items-center">
                                    <h5>SEGUINOS EN</h5>
                                    <span className="d-block">Instagram <a target="_blank" href={settings.social.instagram}>@moncalvo</a></span>
                                    <span className="d-block">Facebook <a target="_blank" href={settings.social.facebook}>@moncalvo</a></span>
                                    {/* <span className="d-block">Twitter <a target="_blank" href={settings.social.twitter}>@fanellitrabalon</a></span> */}
                            </div>
                        </div>
                        <div className="col-12 d-lg-flex justify-content-center">
                            <p className="copy mt-5 text-center">
                                Promoted by <a target={"_blank"} href="https://mediahaus.com.ar"> MediaHaus </a>  - Powered by <a className="text-white" target="_blank" href="https://mediacore.app"> MediaCore </a> 
                                <br />  Todos los derechos reservados ® {getYear()}.
                            </p>
                            {/* <p className="copy mt-5">Plataforma <a className="text-white" target="_blank" href="https://mediacore.com.ar/app"> MediaCore® </a> by <a href="https://mediahaus.com.ar"> MediaHaus® </a> {getYear()}. Todos los derechos reservados.</p> */}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
       
    )
}

export default Footer