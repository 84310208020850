// constantes
const dataInicial = {
    name:'Sergio Alfano Propiedades',
    short_name:'moncalvo',
    base_url:'/',
    // base_url:'/',
    seo:{
        title:'Sergio Alfano Propiedades'
    },
    social:
    {
        facebook:' https://www.facebook.com/profile.php?id=100089762229505',
        instagram:'https://www.instagram.com/alfanopropiedades/',
        
        twitter:'',
        linkedin:'',
        youtube:'',
    },
    contact:
    {
        phone:['+5411 5573-1420'],
        whatsapp:{link:'5491155731420',visual:'+54 911 5573-1420'},
        mail:'sergioalfanopropiedades@gmail.com'
    },
    location:
    {
        address:'Aristóbulo Del Valle 24 Dpto. 2',
        region:'Martinez, Buenos Aires',
        cp:'1640'
    },
    keys:{
        // tokko:'09ce17e5776743c8f0a68f7e19bcb52f7bb8c53d',
        tokko:'5b45057f7de2cd1ecf1ce6c6f47884781d0e914e',
        google:'',
        captcha:'6LcS9SYeAAAAAHnResFXe-ilphz0IqNU9phhPDtL',
        google_tgm:'',
        google_map:'AIzaSyDOdrBLaZgydSYoSpIRywmFxjTECvbiFcA',
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}
