import {Provider} from 'react-redux'
import generateStore from './redux/store'

import {BrowserRouter as Router,Switch,withRouter,Route} from "react-router-dom";


// Bool para producción-local
const production = false;
let routePath = ""
if(production){
  routePath = "/clientes/giesso"
}

//Vistas
import Inicio from './views/home.jsx';
import Propiedades from './views/properties.jsx';
import Propiedad from './views/property.jsx';
import Emprendimientos from './views/developments.jsx';
import Emprendimiento from './views/development.jsx';
import Contacto from './views/contact.jsx';
import Tasaciones from './views/tasaciones.jsx';
import Nosotros from './views/about.jsx';
import Favoritos from './views/favorites.jsx';
import RedirectViewId from "./views/redirectViewId.jsx";

// Componentes
import Header from './components/header.jsx'
import Head from './components/head.jsx'
import ScrollToTop from './components/funcScrollToTop.jsx'
import Footer from './components/footer.jsx'


function App() {
  
  const store = generateStore()

  return (
    <Router>
      <ScrollToTop />
      <Provider store={store}>
      <Head />
      <Header />   
          <Switch>
            <Route path={routePath + '/'} exact>
              <Inicio />
            </Route>    
            <Route path={routePath + "/tokko/:id"} exact component={RedirectViewId} />
            <Route path={routePath + '/ventas/'} >
              <Propiedades />
            </Route> 
            <Route path={routePath + '/alquileres/'} >
              <Propiedades />
            </Route> 
            <Route path={routePath + "/alquileres-temporarios"}>
              <Propiedades />
            </Route>
            <Route path={routePath + '/propiedad/:operation/:type/:location/:id/:address'} exact>
              <Propiedad />
            </Route>     
            <Route path={routePath+'/emprendimientos'} exact>
              <Emprendimientos/>
            </Route> 
            <Route path={routePath+'/emprendimientos/:id'} exact>
              <Emprendimiento />
            </Route>   
            <Route path={routePath+'/tasaciones'} exact>
              <Tasaciones />
            </Route>         
            <Route path={routePath+'/tasaciones/:step'} exact>
              <Tasaciones />
            </Route>  
            <Route path={routePath+'/nosotros'} exact>
              <Nosotros />
            </Route>       
            <Route path={routePath+'/contacto'} exact>
              <Contacto />
            </Route>       
            <Route path={routePath+'/favoritos'} exact>
              <Favoritos />
            </Route>       
          </Switch>
        <Footer />
      </Provider>  
    </Router>
  );
}



export default withRouter(App);
