import React , {useEffect} from 'react'
import { useSelector } from 'react-redux';


// -- Componentes -- //
import Main from '../components/contacto/main'


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Contacto(){

  const settings = useSelector((store) => store.settings);

  useEffect(() => {
      window.scrollTo(0, 0);
      document.title = settings.seo.title
    }, []);
    return(
        <div className="contacto">
          <Main></Main>
        </div>
    )
}

export default Contacto
