import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Nouislider from "nouislider-react";
import { useLocation } from "react-router";
import {checkListTags, updateRouter, loadFiltersRoute, updateFilters, convertIdToString} from '../../functions/functions_property'
import { redux_updateFilters, getPropertiesAction, clearPropertiesAction} from "../../redux/propertiesDucks";
import { changeLocationAction } from "../../redux/themeDucks";

const Filter = () => {
    
  const dispatch = useDispatch();
  const locationStatus = useLocation();

  const [location,setLocation] = useState(window.location.pathname.split("/"))
  const {data} = useSelector(store => store.properties.listFilters)
  const orders = useSelector(store => store.properties.orders)
  const settings = useSelector(store => store.settings)
  const theme = useSelector(store => store.theme)

  // Refs
  const surfaceInput = useRef(null)
  const priceInput = useRef(null)
  const searcherEnvironments  = useRef(null)
  const seacherGenerals  = useRef(null)
  const searcherTags = useRef(null)
  const seacherServices  = useRef(null)

  //Filter Array
  const [filters,setFilters] = useState({
      location:[],
      type:[],
      operation:location[1],
      environments:[],
      price:{
          type:'',
          priceMin:0,
          priceMax:50000000
      },
      surface:{
          type:'',
          surfaceMin:0,
          surfaceMax:100000,
      },
      environments_types:[],
      generals:[],
      services:[],
      specials:[],
  })

  const [filtersLoading,setFiltersLoading] = useState(true)
  
  // Search Filters Inputs 
  const [currentFilterEnvironments,setcurrentFilterEnvironments] = useState('');
  const [currentFilterGenerals,setcurrentFilterGenerals] = useState('');
  const [currentFilterTag,setCurrentFilterTag] = useState('');
  const [currentFilterServices,setCurrentFilterService] = useState('');

  // Price
  const [arsMin,setArsMin] = useState(0);
  const [arsMax,setArsMax] = useState(50000000);
  const [usdMin,setUsdMin] = useState(0);
  const [usdMax,setUsdMax] = useState(2000000);

  const [priceType,setPriceType] = useState(''); 
  const [priceValueMin,setPriceValueMin] = useState(0);
  const [priceValueMax,setPriceValueMax] = useState(0);

  // Surface
  const [cubMin,setcubMin] = useState(0);
  const [cubMax,setcubMax] = useState(10000);
  const [totMin,setTotMin] = useState(0);
  const [totMax,setTotMax] = useState(10000);
  
  const [surfaceType,setSurfaceType] = useState(''); 
  const [surfaceValueMin,setSurfaceValueMin] = useState(0);
  const [surfaceValueMax,setSurfaceValueMax] = useState(0);


  useEffect(() => {
    loadFiltersRoute(location,setFilters,filters,data,setFiltersLoading)
  },[]);

  useEffect(() => {
    if(!filtersLoading){
      dispatch(redux_updateFilters(filters))
      dispatch(clearPropertiesAction())
      dispatch(getPropertiesAction())
      setSurfaceType(filters.surface.type)
      setSurfaceValueMax(filters.surface.surfaceMax)
      setSurfaceValueMin(filters.surface.surfaceMin)
      setPriceType(filters.price.type)
      setPriceValueMin(filters.price.priceMin)
      setPriceValueMax(filters.price.priceMax)
      updateRouter(filters,settings.short_name)
    }
  },[filters,orders]);

  useEffect(() => {
    dispatch(clearPropertiesAction())
    // loadFiltersRoute(location,setFilters,data,setFiltersLoading)
    switch(theme.location){
      case 'alquileres':{
        updateFilters(setFilters,filters,"operation", "alquileres")
        break;
      }
      case 'ventas':{
        updateFilters(setFilters,filters,"operation", "ventas")
        break;
      }
      case 'alquileres-temporarios':{
        updateFilters(setFilters,filters,"operation", "alquileres-temporarios")
        break;
      }
    }
  },[theme.location,locationStatus]);
  

  function toggleFilters(){
      $("#filter").toggleClass("open");
      $(".fixed-bar").toggleClass("d-none")
      $("html").toggleClass("overflow-hidden-mobile");
  }
  //Sliders
  const onSlideSurface = (render, handle, value, un) => {
      setSurfaceValueMin(value[0].toFixed(0));
      setSurfaceValueMax(value[1].toFixed(0));
    };
  const onSlidePrice = (render, handle, value, un) => {
      setPriceValueMin(value[0].toFixed(0));
      setPriceValueMax(value[1].toFixed(0));
  };
  const applySurface = () => {
    if(filters.surface.type !== surfaceType){
      updateFilters(setFilters,filters,"surface-type", surfaceType,{cubMax,totMax})
    }
    updateFilters(setFilters,filters,'surface-min',surfaceValueMin,{totMax,cubMax})
    updateFilters(setFilters,filters,'surface-max',surfaceValueMax,{totMax,cubMax})
  }
  const applyPrice = () => {
    if(filters.price.type !== priceType){
      updateFilters(setFilters,filters,"price-type", priceType,{arsMax,usdMax})
    }
    updateFilters(setFilters,filters,'price-min',priceValueMin,{arsMax,usdMax})
    updateFilters(setFilters,filters,'price-max',priceValueMax,{arsMax,usdMax})
    console.log(filters)
  }

  return data ? (
    <div className="col-lg-4">
      <div className="filter bg-white" id="filter">
        <div className="filter_header">
          <div className="content-top d-flex align-items-lg-center justify-content-between">
            <div className="row d-block d-lg-flex align-items-start justify-content-between w-100">
              <div className="d-flex  justify-content-start justify-content-between ">
                <h3 className="mb-lg-4 mb-2"> Filtrar</h3>
                <button
                  className="ms-4 p-2"
                  onClick={() =>
                    setFilters((filters) => ({
                      location: [],
                      type: [],
                      operation: location[1],
                      environments: [],
                      price: {
                        type: "",
                        priceMin: 0,
                        priceMax: 50000000,
                      },
                      surface: {
                        type: "",
                        surfaceMin: 0,
                        surfaceMax: 10000,
                      },
                      environments_types: [],
                      generals: [],
                      services: [],
                      specials: [],
                    })) 
                    + setSurfaceValueMax(10000)
                    + setSurfaceValueMin(0)
                    + setPriceValueMin(0)
                    + setPriceValueMax(arsMax) 
                    + setPriceType('') 
                    + setSurfaceType('')
                  }
                >
                  LIMPIAR
                </button>
              </div>
            </div>
            <button
              className="close-filter mb-0"
              onClick={() => toggleFilters()}
              
            >
              {" "}
              <div className="content">
                <span></span> <span></span>
              </div>
            </button>
          </div>
          <div className="current-tags mb-lg-5 mb-3">
            {(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{filters.operation}</div>}</div>)}
            {filters.location.map((item,index) =>(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'location',item)} className="btn-delete-tag"></div></div>}</div>))}
            {filters.type.map((item,index) =>(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'type',item)} className="btn-delete-tag"></div></div>}</div>))}
            {filters.price.type !== '' ? (<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{filters.price.type} <div onClick={() =>  updateFilters(setFilters,filters,'price-type',filters.price.type)} className="btn-delete-tag"></div></div>}</div>):''}
            {(filters.price.type !== '' ? <div className="tag d-inline-flex align-items-center justify-content-center">Desde: $ {filters.price.priceMin} Hasta: $ {filters.price.priceMax} <div onClick={() =>  updateFilters(setFilters,filters,'price',0,{usdMax,arsMax})} className="btn-delete-tag"></div></div> : '')}
            {filters.surface.type !== '' ?  (<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{filters.surface.type} <div onClick={() =>  updateFilters(setFilters,filters,'surface-type','')} className="btn-delete-tag"></div></div>}</div>):''}
            {(filters.surface.type !== '' ? <div className="tag d-inline-flex align-items-center justify-content-center">Desde: {filters.surface.surfaceMin} M2 Hasta: {filters.surface.surfaceMax} M2<div onClick={() =>  updateFilters(setFilters,filters,'surface-min',0) +   updateFilters(setFilters,filters,'surface-max',100000)} className="btn-delete-tag"></div></div> : '')}
            {filters.environments.map((item,index) =>(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{item} <div onClick={() =>  updateFilters(setFilters,filters,'environments',item)} className="btn-delete-tag"></div></div>}</div>))}
            {/* {filters.environments !== ''? (<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{filters.environments} <div onClick={() =>  updateFilters(setFilters,filters,'environments',filters.environments)} className="btn-delete-tag"></div></div>}</div>):''} */}
            {filters.environments_types.map((item,index) =>(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'environments_types',item)} className="btn-delete-tag"></div></div>}</div>))}
            {filters.services.map((item,index) =>(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'services',item)} className="btn-delete-tag"></div></div>}</div>))}
            {filters.specials.map((item,index) =>(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{item} <div onClick={() =>  updateFilters(setFilters,filters,'specials',item)} className="btn-delete-tag"></div></div>}</div>))}
            {filters.generals.map((item,index) =>(<div className="d-inline-block">{<div className="tag d-inline-flex align-items-center justify-content-center">{item.name} <div onClick={() =>  updateFilters(setFilters,filters,'generals',item)} className="btn-delete-tag"></div></div>}</div>))}
          </div>
        </div>
        <div className="filter_body">
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-operation"
              aria-expanded="false"
              aria-controls="collapse-operation"
            >
              TIPO DE OPERACIÓN <span className="icon_arrow_filter icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-operation">
                <div className="content-collapse">
                  {data.objects.operation_types
                    .sort((a, b) => b.count - a.count)
                    .map((item, index) =>
                      item.operation_type == 1 ? (
                        <label className="option">
                          <input
                            type="radio"
                            onChange={() =>
                              updateFilters(setFilters,filters,"operation", "Ventas") +
                              dispatch(changeLocationAction("ventas"))
                            }
                            checked={checkListTags(setFilters,filters,"operation",'ventas')}
                            name="type"
                            value={item.operations_type}
                          />
                          <span className="circle"></span>Ventas 
                        </label>
                      ) : "" + item.operation_type == 2 ? (
                        <label className="option">
                          <input
                            type="radio"
                            onChange={() =>
                              updateFilters(setFilters,filters,"operation", "Alquileres")+
                              dispatch(changeLocationAction("alquileres"))
                            }
                            checked={checkListTags(setFilters,filters,"operation",'alquileres')}
                            name="type"
                            value={item.operations_type}
                          />
                          <span className="circle"></span>Alquileres 
                        </label>
                      ) : "" + item.operation_type == 3 ? (
                        <label className="option">
                          <input
                            type="radio"
                            onChange={() =>
                              updateFilters(setFilters,filters,
                                "operation",
                                "alquileres-temporarios"
                              )
                            }
                            checked={checkListTags(setFilters,filters,"operation",'alquileres-temporarios')}
                            name="type"
                            value={item.operations_type}
                          />
                          <span className="circle"></span>Alquileres temporarios
                        </label>
                      ) : (
                        ""
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-location"
              aria-expanded="false"
              aria-controls="collapse-location"
            >
              UBICACIÓN <span className="icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-location">
                <div className="content-collapse">
                  {data.objects.locations
                    .sort((a, b) => b.count - a.count)
                    .map((item, index) => (
                      <label className="option">
                        <input
                          type="checkbox" checked={checkListTags(setFilters,filters,"location",item.location_name)}
                          onChange={() =>
                            dispatch(clearPropertiesAction()) + updateFilters(setFilters,filters,"location", {id:item.location_id,name:item.location_name})
                          }
                          name="type"
                          value={item.location_id}
                        />
                        <span className="square"></span>
                        {item.location_name} 
                      </label>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-type"
              aria-expanded="false"
              aria-controls="collapse-type"
            >
              TIPO DE PROPIEDAD <span className="icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-type">
                <div className="content-collapse">
                  {data.objects.property_types
                    .sort((a, b) => b.count - a.count)
                    .map((item, index) => (
                      <label className="option">
                        <input
                          type="checkbox"
                          checked={checkListTags(setFilters,filters,"type", item.type)}
                          onChange={() => dispatch(clearPropertiesAction()) + updateFilters(setFilters,filters,"type", {id:item.id,name:item.type})}
                          name="type"
                          value={item.id}
                        />
                        <span className="square"></span>
                        {item.type} 
                      </label>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-ambientes"
              aria-expanded="false"
              aria-controls="collapse-ambientes"
            >
              AMBIENTES <span className="icon-arrow"></span>
            </h3>
            {/* <div className="options">
              <div className="collapse" id="collapse-ambientes">
                <div className="content-collapse">
                  <label className="option">
                    <input
                      type="radio"
                      checked={checkListTags(setFilters,filters,"environments", "Monoambiente")}
                      onChange={() =>
                        updateFilters(setFilters,filters,"environments", "Monoambiente")
                      }
                      id="type-<?= $key; ?>"
                      name="ambientes"
                      value="valor-1"
                    />
                    <span className="circle"></span>Monoambiente
                  </label>
                  <label className="option">
                    <input
                      type="radio"
                      onChange={() =>
                        updateFilters(setFilters,filters,"environments", "2 Ambientes")
                      }
                      checked={checkListTags(setFilters,filters,"environments", "2 Ambientes")}
                      id="type-<?= $key; ?>"
                      name="ambientes"
                      value="valor-2"
                    />
                    <span className="circle"></span>2 Ambientes
                  </label>
                  <label className="option">
                    <input
                      type="radio"
                      onChange={() =>
                        updateFilters(setFilters,filters,"environments", "3 Ambientes")
                      }
                      checked={checkListTags(setFilters,filters,"environments", "3 Ambientes")}
                      id="type-<?= $key; ?>"
                      name="ambientes"
                      value="valor-3"
                    />
                    <span className="circle"></span>3 Ambientes
                  </label>
                  <label className="option">
                    <input
                      type="radio"
                      onChange={() =>
                        updateFilters(setFilters,filters,"environments", "4 Ambientes")
                      }
                      checked={checkListTags(setFilters,filters,"environments", "4 Ambientes")}
                      id="type-<?= $key; ?>"
                      name="ambientes"
                      value="valor-4"
                    />
                    <span className="circle"></span>4 Ambientes
                  </label>
                </div>
              </div>
            </div> */}
                        <div className="options">
              <div className="collapse" id="collapse-ambientes">
                <div className="content-collapse">
                  <div className="row">
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          checked={checkListTags(setFilters,filters,"environments", "Monoambiente")}
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "Monoambiente")
                          }
                          id="type-<?= $key; ?>"
                          name="ambientes"
                          value="valor-1"
                        />
                        <span className="circle"></span>1 Ambiente
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "5 Ambientes")
                          }
                          checked={checkListTags(setFilters,filters,"environments", "5 Ambientes")}
                          name="ambientes"
                          value="valor-5"
                        />
                        <span className="circle"></span>5 Ambientes
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "2 Ambientes")
                          }
                          checked={checkListTags(setFilters,filters,"environments", "2 Ambientes")}
                          name="ambientes"
                          value="valor-2"
                        />
                        <span className="circle"></span>2 Ambientes
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "6 Ambientes")
                          }
                          checked={checkListTags(setFilters,filters,"environments", "6 Ambientes")}
                          name="ambientes"
                          value="valor-6"
                        />
                        <span className="circle"></span>6 Ambientes
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "3 Ambientes")
                          }
                          checked={checkListTags(setFilters,filters,"environments", "3 Ambientes")}
                          name="ambientes"
                          value="valor-3"
                        />
                        <span className="circle"></span>3 Ambientes
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "7 Ambientes")
                          }
                          checked={checkListTags(setFilters,filters,"environments", "7 Ambientes")}
                          name="ambientes"
                          value="valor-7"
                        />
                        <span className="circle"></span>7 Ambientes
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "4 Ambientes")
                          }
                          checked={checkListTags(setFilters,filters,"environments", "4 Ambientes")}
                          name="ambientes"
                          value="valor-4"
                        />
                        <span className="circle"></span>4 Ambientes
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          onChange={() =>
                            updateFilters(setFilters,filters,"environments", "8 Ambientes")
                          }
                          checked={checkListTags(setFilters,filters,"environments", "8 Ambientes")}
                          name="ambientes"
                          value="valor-8"
                        />
                        <span className="circle"></span>8 Ambientes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-precio"
              aria-expanded="false"
              aria-controls="collapse-precio"
            >
              RANGO DE PRECIO <span className="icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-precio">
                <div className={"content-collapse " + (!priceType ? "non-slider" : "") }>
                  <div className="row pb-3">
                    {priceType === "" ? (
                      <p className="mb-4 text-primary-color">
                        Seleccioná un tipo de moneda para mayor filtrado *
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          checked={priceType.toLowerCase() === "pesos"}
                          onClick={() =>
                            setPriceType("pesos") +
                            setPriceValueMin(0) +
                            setPriceValueMax(arsMax)
                          }
                          name="type-price"
                          value="valor-1"
                        />
                        <span className="circle"></span>Pesos
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          checked={priceType.toLowerCase() === "dolares"}
                          onClick={() =>
                            setPriceType("dolares") +
                            setPriceValueMin(0) +
                            setPriceValueMax(usdMax)
                          }
                          name="type-price"
                          value="valor-1"
                        />
                        <span className="circle"></span>Dólares
                      </label>
                    </div>
                  </div>
                  {priceType === "" || priceType.toLowerCase() === "pesos" ? (
                    <Nouislider
                      ref={priceInput}
                      connect
                      start={[priceValueMin, priceValueMax]}
                      behaviour="tap"
                      disabled={priceType === ''}
                      range={{
                        min: [arsMin],
                        "40%": [150000, 500],
                        max: [arsMax],
                      }}
                      onSlide={onSlidePrice}
                    />
                  ) : (
                    <Nouislider
                      ref={priceInput}
                      connect
                      start={[priceValueMin, priceValueMax]}
                      behaviour="tap"
                      disabled={priceType === ''}
                      range={{
                        min: [usdMin],
                        "30%": [100000, 500],
                        max: [usdMax],
                      }}
                      onSlide={onSlidePrice}
                    />
                  )}

                  <div className="d-flex w-100 justify-content-between">
                    <div className="mt-3 text-black">
                      {priceValueMin
                        ? priceType.toLowerCase() === "dolares"
                          ? "U$D" + Intl.NumberFormat("de-DE").format(priceValueMin)
                          : "$" + Intl.NumberFormat("de-DE").format(priceValueMin)
                        : priceType.toLowerCase() === "dolares"
                        ? "U$D " + 0
                        : "$ " + 0}
                    </div>
                    <div className="mt-3 text-black">
                      {priceValueMax
                        ? priceType.toLowerCase() === "dolares"
                          ? "U$D" + Intl.NumberFormat("de-DE").format(priceValueMax)
                          : "$" + Intl.NumberFormat("de-DE").format(priceValueMax)
                        : priceType.toLowerCase() === "dolares"
                        ? "U$D " + Intl.NumberFormat("de-DE").format(usdMax)
                        : "$ " + Intl.NumberFormat("de-DE").format(arsMax)}
                    </div>
                  </div>
                </div>
                <button className="btn border-none p-2 px-4 mt-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse-precio"
                  aria-expanded="false"
                  aria-controls="collapse-precio" 
                  disabled ={priceType === '' } onClick={() => applyPrice()}>APLICAR</button>
              </div>
            </div>
          </div>
          
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-rango"
              aria-expanded="false"
              aria-controls="collapse-rango"
            >
              RANGO DE SUPERFICIE<span className="icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-rango">
                <div className={"content-collapse " +(!filters.surface.type ? "non-slider" : "")}>
                  <div className="row pb-3">
                    {surfaceType === "" ? ( <p className="mb-4 text-primary-color"> Seleccioná tipo de superficie * </p> ) : ( "" )}
                    <div className="col-6">
                      <label className="option">
                        <input type="radio" checked={surfaceType.toLowerCase() === "cubierta"} 
                        onClick={() =>
                            setSurfaceType("cubierta") +
                            setSurfaceValueMin(0) +
                            setSurfaceValueMax(cubMax)
                          }
                          name="type-surface"
                          value="valor-1"
                        />
                        <span className="circle"></span>Cubierta
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="option">
                        <input
                          type="radio"
                          checked={surfaceType.toLowerCase() === "total"}
                          onClick={() =>
                            setSurfaceType("total") +
                            setSurfaceValueMin(0) +
                            setSurfaceValueMax(totMax)
                          }
                          name="type-surface"
                          value="valor-2"
                        />
                        <span className="circle"></span>Total
                      </label>
                    </div>
                  </div>
                  {surfaceType === "" || surfaceType.toLowerCase() === "cubierta" ? (
                    <Nouislider
                      ref={surfaceInput}
                      connect
                      start={[surfaceValueMin, surfaceValueMax]}
                      behaviour="tap"
                      disabled={surfaceType === ''}
                      range={{
                        min: [cubMin],
                        "50%":[400,1000],
                        // "20%": [cubMax, cubMax],
                        max: [cubMax],
                      }}
                      onSlide={onSlideSurface}
                    />
                  ) : (
                    <Nouislider
                      ref={surfaceInput}
                      connect
                      start={[surfaceValueMin, surfaceValueMax]}
                      behaviour="tap"
                      range={{
                        min: [totMin],
                        "100%": [totMax, totMax],
                        max: [totMax],
                      }}
                      disabled={surfaceType === ''}
                      onSlide={onSlideSurface}
                    />
                  )}

                  <div className="d-flex w-100 justify-content-between">
                    <div className="mt-3 text-black">
                      {surfaceValueMin
                        ? (filters.surface.type.toLowerCase() == "total"
                            ? Intl.NumberFormat("de-DE").format(surfaceValueMin)
                            : Intl.NumberFormat("de-DE").format(surfaceValueMin)) + " M2"
                        : (filters.surface.type.toLowerCase() == "total" ? Intl.NumberFormat("de-DE").format(totMin) : Intl.NumberFormat("de-DE").format(cubMin)) +
                          " M2"}
                    </div>
                    <div className="mt-3 text-black">
                      {surfaceValueMax
                        ? (filters.surface.type == "Cubierta"
                            ? sIntl.NumberFormat("de-DE").format(urfaceValueMax)
                            : Intl.NumberFormat("de-DE").format(surfaceValueMax)) + " M2"
                        : (filters.surface.type == "Cubierta"
                            ? Intl.NumberFormat("de-DE").format(totMax)
                            : Intl.NumberFormat("de-DE").format(cubMax)) + " M2"}
                    </div>
                  </div>
                  <button className="btn border-none p-2 px-4 mt-3 "               data-bs-toggle="collapse"
              data-bs-target="#collapse-rango"
              aria-expanded="false"
              aria-controls="collapse-rango" disabled ={surfaceType === '' } onClick={() => applySurface()}>APLICAR</button>
                </div>
              </div>
            </div>
          </div>
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-type-ambientes"
              aria-expanded="false"
              aria-controls="collapse-type-ambientes"
            >
              TIPOS DE AMBIENTES <span className="icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-type-ambientes">
                <div className="content-collapse">
                  {data.objects.tags
                    .filter((element) => element.tag_type === 2)
                    .sort((a, b) => b.count - a.count)
                    .map((item, index) => (
                      item.tag_name.toLowerCase() !== 'oficina' && item.tag_name.toLowerCase() !== 'lavadero' ?
                      <label className="option">
                        <input
                          type="checkbox"
                          checked={checkListTags(setFilters,filters,"environments_types",{id:item.tag_id,name:item.tag_name})}
                          onChange={() =>
                            dispatch(clearPropertiesAction()) + updateFilters(setFilters,filters,"environments_types", {id:item.tag_id,name:item.tag_name})
                          }
                          name="type"
                          value={item.tag_id}
                        />
                        <span className="square"></span>
                        {item.tag_name}
                      </label>
                    :''))}
                </div>
              </div>
            </div>
          </div>
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-generales"
              aria-expanded="false"
              aria-controls="collapse-generales"
            >
              GENERALES <span className="icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-generales">
                <div className="content-collapse">
                  <input
                    type="text"
                    ref={seacherGenerals}
                    onChange={() =>
                      setcurrentFilterGenerals(seacherGenerals.current.value)
                    }
                    placeholder="Buscar generales.."
                  />
                  <div className="content-collapse">
                    {data.objects.tags
                      .filter((element) => element.tag_type === 3)
                      .sort((a, b) => b.count - a.count)
                      .map((item, index) => (
                        item.tag_name.toLowerCase() !== 'local' ?
                        <label className={
                            "option " +
                            (item.tag_name
                              .toUpperCase()
                              .indexOf(currentFilterGenerals.toUpperCase()) > -1
                              ? "d-block"
                              : "d-none")}>
                          <input type="checkbox" checked={checkListTags(setFilters,filters,"generals", {id:item.tag_id,name:item.tag_name})}  onChange={() => dispatch(clearPropertiesAction()) +  updateFilters(setFilters,filters,"generals", {id:item.tag_id,name:item.tag_name})} name="type" value={item.tag_id}
                          />
                          <span className="square"></span>
                          {item.tag_name} 
                        </label>
                      :''))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-servicios"
              aria-expanded="false"
              aria-controls="collapse-servicios"
            >
              SERVICIOS <span className="icon-arrow"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-servicios">
                <div className="content-collapse">
                  <input
                    type="text"
                    ref={seacherServices}
                    onChange={() =>
                      setCurrentFilterService(seacherServices.current.value)
                    }
                    placeholder="Buscar servcios.."
                  />
                  <div className="content-collapse">
                    {data.objects.tags
                      .filter((element) => element.tag_type === 1)
                      .sort((a, b) => b.count - a.count)
                      .map((item, index) => (
                        <label
                          className={
                            "option " +
                            (item.tag_name
                              .toUpperCase()
                              .indexOf(currentFilterServices.toUpperCase()) > -1
                              ? "d-block"
                              : "d-none")
                          }
                        >
                          <input
                            type="checkbox"
                            checked={checkListTags(setFilters,filters,"services", {id:item.tag_id,name:item.tag_name})}
                            onChange={() =>
                              dispatch(clearPropertiesAction()) + updateFilters(setFilters,filters,"services", {id:item.tag_id,name:item.tag_name})
                            }
                            name="type"
                            value={item.tag_id}
                          />
                          <span className="square"></span>
                          {item.tag_name} 
                        </label>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="filters-group py-lg-4 py-3">
            <h3
              className="collapse-btn collapsed my-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse-especials"
              aria-expanded="false"
              aria-controls="collapse-especials"
            >
              FILTROS ESPECIALES<span className="current-status"></span>
            </h3>
            <div className="options">
              <div className="collapse" id="collapse-especials">
                <div className="content-collapse">
                  <input
                    type="text"
                    ref={searcherTags}
                    onChange={() =>
                      setCurrentFilterTag(searcherTags.current.value)
                    }
                    placeholder="Buscar especiales.."
                  />
                  <div className="content-collapse">
                    <label
                      className={
                        "option " +
                        ("Especial 1"
                          .toUpperCase()
                          .indexOf(currentFilterTag.toUpperCase()) > -1
                          ? "d-block"
                          : "d-none")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={checkListTags(setFilters,filters,"specials", "Especial 1")}
                        onChange={() => updateFilters(setFilters,filters,"specials", "Especial 1")}
                        id="type-<?= $key; ?>"
                        name="type"
                        value="valor-1"
                      />
                      <span className="square"></span>Especial 1
                    </label>
                    <label
                      className={
                        "option " +
                        ("Especial 2".indexOf(currentFilterTag) > -1
                          ? "d-block"
                          : "d-none")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={checkListTags(setFilters,filters,"specials", "Especial 2")}
                        onChange={() => updateFilters(setFilters,filters,"specials", "Especial 2")}
                        id="type-<?= $key; ?>"
                        name="type"
                        value="valor-1"
                      />
                      <span className="square"></span>Especial 2
                    </label>
                    <label
                      className={
                        "option " +
                        ("Especial 3".indexOf(currentFilterTag) > -1
                          ? "d-block"
                          : "d-none")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={checkListTags(setFilters,filters,"specials", "Especial 3")}
                        onChange={() => updateFilters(setFilters,filters,"specials", "Especial 3")}
                        id="type-<?= $key; ?>"
                        name="type"
                        value="valor-1"
                      />
                      <span className="square"></span>Especial 3
                    </label>
                    <label
                      className={
                        "option " +
                        ("Especial 4".indexOf(currentFilterTag) > -1
                          ? "d-block"
                          : "d-none")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={checkListTags(setFilters,filters,"specials", "Especial 4")}
                        onChange={() => updateFilters(setFilters,filters,"specials", "Especial 4")}
                        id="type-<?= $key; ?>"
                        name="type"
                        value="valor-1"
                      />
                      <span className="square"></span>Especial 4
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Filter;
