import { useSelector } from 'react-redux'
import BreadCrumbs from '../breadcrumbs'

const   Main = () =>{
    const settings = useSelector(store => store.settings)
    return (
        <section className="main">
            <div className="container-fluid full-fluid">
                <div className="row">
                    <div className="col-lg-5 h-100 d-flex justify-content-center flex-column corte_diagonal">
                        <div className="col-12 brad_crumbs_nosotros text-start text-start">
                            <BreadCrumbs props={[
                                {name:'Home',route:settings.base_url +"",location:''},
                                {name:'Nosotros',route:'',location:'',active:true}
                            ]}/>
                        </div>
                        <span className="text_acerca text-start mb-lg-4 mb-2">Desde 2003</span>
                        <h2 className="title_nosotros text-start">Nosotros</h2>
                        <div className="content-logo position-relative">
                        <img className="logo-absolute rotating" src="assets/images/circle_text_white.svg" alt="Honestidad - Profesionalismo - Confidencialidad - Transparencia" />
                        </div>

                    </div>
                    <div className="col-lg-7 ps-0 h-100 h-sm-auto div_body_mayor">
                        <div className="row content-main ms-0 d-flex">
                            <div className="div_body_nosotros">
                                <div className="col-lg-12 title">
                                    <h3>Desde 2003 brindando el mejor servicio.</h3>
                                </div>
                                <div className="col-lg-12 text">
                                    <p>
                                        Somos una empresa con 18 años en el mercado inmobilario brindando respeto por nuestros clientes, profesionalismo, confidencialidad, transparencia, honestidad, y seguridad en todas las operaciones realizadas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Main