import React from 'react'
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import { changeLocationAction } from '../../redux/themeDucks';

function About (){
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    return  (
        <div className="about div_tasa_propiedad d-flex align-items-center">
            <div className="container-fluid">
                <div className="col-12 d-lg-flex d-none justify-content-end">
                    <div className="content-logo">
                        {/* <img className="logo-absolute rotating" src="assets/images/black-text-circle.svg" alt="Honestidad - Profesionalismo - Confidencialidad - Transparencia" /> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-md-12 d-flex flex-column text-content justify-content-center contenedor_text_about">
                        <small className="text-white mb-5 small_text">Conocé el valor de tu propiedad</small>
                        <h2 className="text-white">Ahora podés tasar tu propiedad  de forma rápida y segura. Completá los datos así podemos brindarte un valor aproximado. <br /> ¿Calculamos? </h2>
                    </div>
                    <div className="col-lg-4 col-md-12 bg-img d-flex justify-content-center align-items-end div_button_img">
                        <Link to={settings.base_url + "tasaciones"}  onClick={() => dispatch(changeLocationAction("tasaciones"))} 
                        className="btn d-inline-block"><span> TASÁ TU PROPIEDAD AHORA </span> </Link>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

export default About
